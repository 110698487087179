import FullPageLayout from "../components/layouts/FullPageLayout";
import {useLoaderData, useNavigate} from "react-router-dom";
import React from "react";
import UserProfileCard from "../components/utils/UserProfileCard";
import Machine from "../models/Machine";
import UserPermissionsCard from "../components/utils/UserPermissionsCard";
import {Button, Card, CardActionArea, CardContent, Grid, Stack, Typography} from "@mui/material";
import User from "../models/User";
import {ArrowForward, Code, Dns, Groups, Person, Receipt, Storage} from "@mui/icons-material";

export const loader = appContext => async ({ params }) => {
    const currentUser = await appContext.user.require();

    const [
        rawAllMachines,
        rawUserPermissions
    ] = await Promise.all([
        appContext.axios.get(`machines`),
        appContext.axios.get(`users/${currentUser.id}/machines`)
    ]);

    const allMachines = rawAllMachines.data.machines.map(Machine.fromApiData).sort((a, b) => (a.hostName.toLowerCase() < b.hostName.toLowerCase() ? -1 : 1));
    const selectedUserPermissions = allMachines.map((machine) => {
        const permissionEntries = rawUserPermissions.data.permissions.filter(perm => perm.machine_id === machine.id);
        if (permissionEntries.length === 0) {
            return {
                machine: machine.id,
                permission_level: 2,
            };
        } else {
            return {
                machine: permissionEntries[0].machine_id,
                permission_level: Number.parseInt(permissionEntries[0].permission_level)
            };
        }
    });

    return {
        currentUser: currentUser,
        allMachines: allMachines,
        currentUserPermissions: selectedUserPermissions,
    };
}


export default function HomePage(props) {
    const {currentUser, allMachines, currentUserPermissions} = useLoaderData();
    const navigate = useNavigate();

    return (
        <FullPageLayout
            title="Overview"
            description="Welcome to the RootSec Lab User Management!"
            user={currentUser}
        >
            <Typography variant="h2" sx={{mb: 2}}>
                Main actions
            </Typography>
            <Grid container spacing={2}>
                {currentUser.isAdmin() && <Grid item xs={12} sm={6}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => navigate(`/users`)}
                        startIcon={<Groups />}
                        fullWidth
                    >
                        Manage Users
                    </Button>
                </Grid>}
                <Grid item xs={12} sm={6} md={currentUser.isAdmin() ? 6 : 4}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => navigate(`/machines`)}
                        startIcon={<Storage />}
                        fullWidth
                    >
                        {currentUser.isAdmin() ? "Manage" : "View"} Machines
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => navigate(`/users/${currentUser.id}`)}
                        startIcon={<Person />}
                        fullWidth
                    >
                        My Profile
                    </Button>
                </Grid>
                {currentUser.isAdmin() && <Grid item xs={12} sm={6} md={4}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => navigate(`/queues`)}
                        startIcon={<Receipt />}
                        fullWidth
                    >
                        Action Queue
                    </Button>
                </Grid>}
                <Grid item xs={12} sm={6} md={4}>
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => navigate(`/docs`)}
                        startIcon={<Code />}
                        fullWidth
                    >
                        API Documentation
                    </Button>
                </Grid>
            </Grid>
            <Typography variant="h2" gutterBottom sx={{mb: 2, mt: 2}}>
                Account information
            </Typography>
            <UserProfileCard
                user={currentUser}
                hideHeader
            />
            <Typography variant="h2" sx={{mb: 2, mt: 4}}>
                Available lab machines
            </Typography>
            <UserPermissionsCard
                user={currentUser}
                machines={allMachines}
                userPermissions={currentUserPermissions}
                hideHeader
            />
        </FullPageLayout>
    )
}