import './App.css';
import React from 'react'
import MainRouter from './pages/MainRouter'
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {orange} from "@mui/material/colors";
import ApplicationContext from "./contexts/ApplicationContext";
import axios from "axios";
import {AuthenticationRequiredError} from "./models/Exceptions";
import {SnackbarProvider, withSnackbar} from "notistack";
import User from "./models/User";

class AppContextProvider extends React.Component {

    constructor(props) {
        super(props);

        const apiToken = sessionStorage.getItem("lum_api_token");

        this.alreadyMounted = false;

        this.state = {
            uninitialized: true,
            user: undefined,
        };

        const isDebugEnv = window.location.hostname.includes("localhost");
        this.axios = axios.create({
            baseURL: isDebugEnv ? `http://${window.location.hostname}:8000/api/` : `https://${window.location.hostname}/api/`,
            headers: {
                common: {
                    Authorization: (apiToken === "") ? null : `Bearer ${apiToken}`
                }
            }
        });
    }

    componentDidMount() {
        if (!this.alreadyMounted) {
            this.alreadyMounted = true;

            this.reloadUserSession();

            // Refresh user session every ten seconds
            this.setState({intervalId: setInterval(this.reloadUserSession.bind(this), 15*1000)});
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    reloadUserSession() {
        if (this.state.uninitialized) {
            const apiToken = sessionStorage.getItem("lum_api_token");

            this.axios.defaults.headers["Authorization"] = (apiToken === "") ? null : `Bearer ${apiToken}`;
        }

        const unauthenticatedHandler = () => {
            sessionStorage.setItem("lum_api_token", "");

            this.axios.defaults.headers["Authorization"] = null;

            if (this.state.user !== null) {
                this.setState({
                    user: null,
                    uninitialized: false
                });
            }
        }

        this.axios.get("auth/state").then(r => {
            switch (r.data.state) {
                case "anonymous":
                    unauthenticatedHandler();
                    break;
                case "authenticated":
                    let _user = User.fromApiData(r.data);

                    if (!_user.equals(this.state.user)) {
                        this.setState({
                            user: _user,
                            uninitialized: false
                        });
                    }
                    break;

                default:
                    console.error("Received malformed response:", r);
                    break;
            }
        }).catch(r => {
            // Check for expired token
            if (r.response.status === 401) {
                unauthenticatedHandler();
            }
        });
    }

    render() {
        // Setup theming
        const theme = createTheme({
            status: {
                danger: orange[500],
            },
            palette: {
                primary: {
                    main: "#00767a"
                },
                background: {
                    default: "#f8f8f8"
                },
            },
            shape: {
                borderRadius: 10
            },
            typography: {
                fontFamily: '"IBM Plex Sans", Roboto, Helvetica, "Segoe UI", Arial, sans-serif',
                h1: {
                    fontSize: "2em",
                    fontWeight: 600
                },
                h2: {
                    fontSize: "1.5em",
                    fontWeight: 600
                },
                h3: {
                    fontSize: "1.17em",
                    fontWeight: 600
                },
                h4: {
                    fontSize: "1em",
                    fontWeight: 600
                },
                h5: {
                    fontSize: "0.83em",
                    fontWeight: 600
                },
                h6: {
                    fontSize: "0.67em",
                    fontWeight: 600
                },
                button: {
                    fontWeight: 600,
                    textTransform: "inherit"
                }
            },
            components: {
                MuiAppBar: {
                    styleOverrides: {
                        root: {
                            background: "#263238"
                        }
                    }
                },
                MuiFormControl: {
                    styleOverrides: {
                        root: ({theme}) => ({
                            marginTop: theme.spacing(2),
                            marginBottom: theme.spacing(1)
                        })
                    }
                },
                MuiCard: {
                    styleOverrides: {
                        root: ({theme}) => ({
                            marginBottom: theme.spacing(2)
                        })
                    }
                },
                MuiDialogTitle: {
                    styleOverrides: {
                        root: ({theme}) => ({
                            fontSize: "1.25rem"
                        })
                    }
                },
                MuiDialogContentText: {
                    styleOverrides: {
                        root: ({theme}) => ({
                            marginBottom: theme.spacing(2)
                        })
                    }
                },
                MuiTableCell: {
                    styleOverrides: {
                        head: ({theme}) => ({
                            fontWeight: 600
                        })
                    }
                },
                MuiMenuItem: {
                    styleOverrides: {
                        selected: ({theme}) => ({
                            fontWeight: 600
                        })
                    }
                },
            }
        });

        const changeToken = (apiToken) => {
            this.axios.defaults.headers["Authorization"] = (apiToken === "") ? null : `Bearer ${apiToken}`;
            sessionStorage.setItem("lum_api_token", apiToken);
            this.reloadUserSession();
        };

        const _logout = async () => {
            try {
                await this.axios.delete("auth/logout");
                this.props.enqueueSnackbar("Logout complete.");
            } finally {
                changeToken("");
            }
        };

        const _login = async (username, password) => {
            let data = null;

            try {
                const loginRequest = await this.axios.post("auth/login", {
                    username: username,
                    password: password,
                }, {
                    headers: {
                        Authorization: null
                    }
                });
                data = loginRequest.data;
            } catch (e) {
                switch (e.response.status) {
                    case 400:
                        throw new Error("Invalid username or password.");
                    case 403:
                        throw new Error("Your account has not yet been confirmed by an administrator.");
                    default:
                        throw new Error("The server responded with an unexpected response.");
                }
            }

            if (typeof data?.token !== 'string') {
                throw new Error("The server responded with an unexpected response.");
            }

            this.props.enqueueSnackbar("Successfully logged in.");
            changeToken(data.token);
        }

        const _requireUser = async () => {
            if (this.state.user === null) {
                throw new AuthenticationRequiredError("Not authenticated.");
            }
            return this.state.user;
        };

        const _getUser = async () => {
            return this.state.user;
        }

        const _fetch = (() => {
            this.componentWillUnmount();
            this.reloadUserSession();
            this.componentDidMount();
        });

        const appContext = {
            axios: this.axios,
            enqueueSnackbar: this.props.enqueueSnackbar,
            user: {
                login: _login,
                logout: _logout,
                fetch: _fetch.bind(this),
                require: _requireUser,
                get: _getUser,
            }
        }

        return (
                <ThemeProvider theme={theme}>
                    <>
                        <CssBaseline />
                        { this.state.uninitialized ? null :
                            <ApplicationContext.Provider value={appContext}>
                                <MainRouter />
                            </ApplicationContext.Provider>
                        }

                    </>
                </ThemeProvider>
        );
    }
}


function App() {
    const AppCtxWithSnackbar = withSnackbar(AppContextProvider);

    return (
        <React.StrictMode>
            <SnackbarProvider maxSnack={5}>
                <AppCtxWithSnackbar />
            </SnackbarProvider>
        </React.StrictMode>
    )
}

export default App;