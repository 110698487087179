import React from 'react';
import {
    Alert,
    AppBar,
    Button,
    Collapse, Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel,
    List, ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText, OutlinedInput, Snackbar, Toolbar, Tooltip, Typography, useMediaQuery, useTheme
} from "@mui/material";
import {Add, Close, ExpandLess, ExpandMore, RemoveCircleOutline, VpnKey} from "@mui/icons-material";
import {useFetcher} from "react-router-dom";
import copy from 'copy-text-to-clipboard';
import SlideUpTransition from "./SlideUpTransition";

function SshKeysListEntry({ user, sshKey, canDelete }) {
    const fetcher = useFetcher();

    const [snackbarVisible, setSnackbarVisible] = React.useState(false);

    const [dialogState, setDialogState] = React.useState({
        open: false,
        loading: false
    });

    const closeHandler = e => {
        setDialogState({
            ...dialogState,
            open: false,
        });
    }

    const copyHandler = () => {
        setSnackbarVisible(true);
        copy(sshKey.publicKey);
        setTimeout(() => setSnackbarVisible(false), 2000);
    }

    return <>
        <ListItem
            disablePadding
            secondaryAction={
                canDelete &&
                <Tooltip title="Remove public key">
                    <IconButton
                        onMouseDown={e => e.preventDefault()}
                        color="error"
                        onClick={() => setDialogState({
                            ...dialogState,
                            open: true,
                        })}
                    >
                        <RemoveCircleOutline />
                    </IconButton>
                </Tooltip>
            }
        >
            <ListItemButton sx={{ pl: 4 }} onClick={copyHandler}>
                <ListItemIcon>
                    <VpnKey />
                </ListItemIcon>
                <ListItemText
                    primary={<small><code style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', display: 'block'}}>{sshKey.publicKey}</code></small>}
                    secondary="Click to copy."
                />
            </ListItemButton>
        </ListItem>

        <Dialog open={dialogState.open} onClose={closeHandler}>
            <fetcher.Form method="delete" action={`/users/${user.id}/deleteKey`} onSubmit={closeHandler}>
                <input type="hidden" name="key" value={sshKey.id} />
                <DialogTitle>Delete Public Key</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to remove the following key?
                    </DialogContentText>

                    <pre>{sshKey.publicKey}</pre>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeHandler}>Cancel</Button>
                    <Button type="submit" color="error">Remove key</Button>
                </DialogActions>
            </fetcher.Form>
        </Dialog>

        <Snackbar
            open={snackbarVisible}
            message="Copied key to clipboard."
        />
    </>
}

function SshKeyListAdd({ user }) {
    const fetcher = useFetcher();
    const useFullScreenDialog = useMediaQuery(useTheme().breakpoints.down('md'));

    const [dialogState, setDialogState] = React.useState({
        open: false,
        loading: false,
        errors: [],
    });

    React.useEffect(() => {
        const actionData = fetcher.data;
        if (Array.isArray(actionData)) {
            setDialogState(dialogState => {
                if (!dialogState.loading) {
                    return dialogState;
                }

                return {
                    ...dialogState,
                    open: actionData.length !== 0,
                    loading: false,
                    errors: actionData
                };
            });
        }
    }, [fetcher.data]);

    const closeHandler = e => {
        setDialogState({
            ...dialogState,
            open: false,
        });
    }

    const submitHandler = e => {
        setDialogState({
            ...dialogState,
            loading: true,
        })
    }

    return <>
        <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => setDialogState({
                ...dialogState,
                open: true,
            })}
        >
            <ListItemIcon>
                <Add />
            </ListItemIcon>
            <ListItemText primary="Add key" />
        </ListItemButton>

        <Dialog
            open={dialogState.open}
            onClose={closeHandler}
            maxWidth="md"
            fullWidth
            fullScreen={useFullScreenDialog}
            TransitionComponent={useFullScreenDialog ? SlideUpTransition : undefined}
        >
            <fetcher.Form method="post" action={`/users/${user.id}/addKey`} onSubmit={submitHandler}>
                { useFullScreenDialog &&
                    <>
                        <AppBar>
                            <Toolbar>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={closeHandler}
                                    aria-label="close"
                                >
                                    <Close />
                                </IconButton>
                                <Typography sx={{ ml: 2, flex: 1 }} variant="h3" component="div">
                                    Add public key
                                </Typography>
                                <Button autoFocus color="inherit" type="submit">
                                    Add
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <Toolbar />
                    </>

                }
                { useFullScreenDialog ||
                    <DialogTitle>Add public key</DialogTitle>
                }
                <DialogContent>
                    <DialogContentText>
                        Please enter the public SSH key you want to add to the user.
                    </DialogContentText>

                    <FormControl sx={{ my: 2 }} variant="outlined" fullWidth>
                        <InputLabel htmlFor="outlined-adornment-password">Public SSH key</InputLabel>
                        <OutlinedInput
                            type="text"
                            required
                            autoFocus
                            name="publicKey"
                            disabled={dialogState.loading}
                            startAdornment={
                                <InputAdornment position="start">
                                    <VpnKey />
                                </InputAdornment>
                            }
                            label="Public SSH key"
                        />
                        <FormHelperText>
                            Public keys usually begin with <code>ssh-...</code>
                        </FormHelperText>
                    </FormControl>
                    {
                        dialogState.errors.map((message, index) => (
                            <Alert key={`alert-addkey-${index}`} variant="outlined" severity="error" sx={{mt: 2}}>
                                {message}
                            </Alert>
                        ))
                    }
                </DialogContent>

                { useFullScreenDialog ||
                    <DialogActions>
                        <Button disabled={dialogState.loading} onClick={closeHandler} type="button">Cancel</Button>
                        <Button disabled={dialogState.loading} variant="contained" type="submit">Add key</Button>
                    </DialogActions>
                }
            </fetcher.Form>
        </Dialog>
    </>
}

function SshKeysList({ user, canChangeSshKeys }) {

    const [keysShown, setKeysShown] = React.useState(false);


    let keysEnrolledText = "";
    switch (user.sshKeys.length) {
        case 0:
            keysEnrolledText = "No keys enrolled";
            break;

        case 1:
            keysEnrolledText = "One key enrolled";
            break;

        default:
            keysEnrolledText = `${user.sshKeys.length} keys enrolled`;
    }

    const keys = user.sshKeys.sort((a, b) => a.publicKey < b.publicKey ? -1 : 1);

    return (canChangeSshKeys || keys.length > 0) ? <>
        <ListItemButton onClick={() => setKeysShown(!keysShown)}>
            <ListItemIcon>
                <VpnKey/>
            </ListItemIcon>
            <ListItemText primary={keysEnrolledText} secondary="Public SSH keys"/>
            {keysShown ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={keysShown} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {
                    keys.map((key, index) =>
                        <SshKeysListEntry key={`ssh-key-${index}`} sshKey={key} user={user} canDelete={canChangeSshKeys} />
                    )
                }
                { canChangeSshKeys &&
                    <SshKeyListAdd user={user} />
                }
            </List>
        </Collapse>
    </> :
    <ListItem>
        <ListItemIcon>
            <VpnKey/>
        </ListItemIcon>
        <ListItemText primary={keysEnrolledText} secondary="Public SSH keys"/>
    </ListItem>
}

export default SshKeysList;