import {createBrowserRouter, RouterProvider} from "react-router-dom";
import LoginPage, { loader as LoginLoader, action as LoginAction } from "./authentication/LoginPage";
import RegisterPage, { loader as RegisterLoader, action as RegisterAction } from "./authentication/RegisterPage";
import ErrorPage from "./ErrorPage";
import HomePage, { loader as HomeLoader } from "./HomePage";
import UsersIndexPage, { loader as UsersIndexLoader, action as UsersIndexAction } from "./users/Index";
import UsersEntryPage, { loader as UsersEntryLoader, action as UsersEntryAction } from "./users/Entry";
import MachinesIndexPage, { loader as MachinesIndexLoader, action as MachinesIndexAction } from "./machines/Index";
import MachinesEntryPage, { loader as MachinesEntryLoader, action as MachinesEntryAction } from "./machines/Entry";
import QueuesIndexPage, { loader as QueuesIndexLoader, action as QueuesIndexAction } from "./queues/Index";
import DocsIndexPage, { loader as DocsIndexLoader } from "./docs/Index";
import {useContext} from "react";
import applicationContext from "../contexts/ApplicationContext";

function MainRouter(props) {
    const appContext = useContext(applicationContext);

    const router = createBrowserRouter([
        {
            path: "/",
            loader: HomeLoader(appContext),
            element: <HomePage />,
            errorElement: <ErrorPage />,
        },
        {
            path: "/auth/login",
            loader: LoginLoader(appContext),
            action: LoginAction(appContext),
            element: <LoginPage />
        },
        {
            path: "/auth/register",
            loader: RegisterLoader(appContext),
            action: RegisterAction(appContext),
            element: <RegisterPage />
        },
        {
            path: "/users/",
            loader: UsersIndexLoader(appContext),
            action: UsersIndexAction(appContext),
            element: <UsersIndexPage/>,
            errorElement: <ErrorPage/>,
        },
        {
            path: "/users/:userId",
            loader: UsersEntryLoader(appContext),
            action: UsersEntryAction(appContext),
            element: <UsersEntryPage />,
            errorElement: <ErrorPage/>,
            children: [
                {
                    path: "/users/:userId/:action",
                    action: UsersEntryAction(appContext),
                    errorElement: <ErrorPage/>,
                    element: null
                }
            ]
        },
        {
            path: "/machines/",
            loader: MachinesIndexLoader(appContext),
            action: MachinesIndexAction(appContext),
            element: <MachinesIndexPage/>,
            errorElement: <ErrorPage/>,
        },
        {
            path: "/machines/:machineId",
            loader: MachinesEntryLoader(appContext),
            action: MachinesEntryAction(appContext),
            element: <MachinesEntryPage />,
            errorElement: <ErrorPage/>,
            children: [
                {
                    path: "/machines/:machineId/:action",
                    action: MachinesEntryAction(appContext),
                    errorElement: <ErrorPage/>,
                    element: null
                }
            ]
        },
        {
            path: "/queues/",
            loader: QueuesIndexLoader(appContext),
            action: QueuesIndexAction(appContext),
            element: <QueuesIndexPage />,
            errorElement: <ErrorPage/>,
        },
        {
            path: "/docs/",
            loader: DocsIndexLoader(appContext),
            element: <DocsIndexPage />,
            errorElement: <ErrorPage/>,
        },
    ], {
        basename: "/ui/",
    });

    return (
        <RouterProvider router={router} />
    );
}

export default MainRouter