import React from "react";
import {useFetcher, useLoaderData, useNavigate} from "react-router-dom";
import FullPageLayout from "../../components/layouts/FullPageLayout";
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import Machine from "../../models/Machine";
import {PlayArrow} from "@mui/icons-material";

export const loader = appContext => async ({ params }) => {
    const currentUser = await appContext.user.require();
    let queue = (await appContext.axios.get("/machines/queue")).data?.tasks;
    let machines = (await appContext.axios.get("/machines")).data?.machines;

    // Check api response for correct format
    if (!Array.isArray(machines)) {
        throw new Error("Received invalid response from server.");
    }

    // Convert api response to client user model and sort by username
    machines = machines.map(Machine.fromApiData).sort((a, b) => (a.hostName.toLowerCase() < b.hostName.toLowerCase() ? -1 : 1));

    return {
        currentUser: currentUser,
        queue: queue,
        machines: machines,
    };
}

export const action = appContext => async ({ request, params }) => {
    try {
        appContext.enqueueSnackbar("Running all queued actions, please wait.");
        await appContext.axios.post(`/machines/queue`);
        appContext.enqueueSnackbar("Finished running all queued actions.");
        return [];
    } catch (e) {
        if (e.response.status === 423) {
            appContext.enqueueSnackbar("Manual dequeuing already in progress.");
        } else {
            appContext.enqueueSnackbar("An unknown error occurred.");
        }
        return [];
    }
}

export function QueuesItem({ item, machines }) {
    let machineDisplay = "Unknown Machine";
    machines.forEach(machine => {
        if (machine.id === item.targetMachine_id) {
            machineDisplay = machine.displayName;
        }
    });

    function functionToName(queueItem) {
        switch (queueItem.functionId) {
            case 0: return "Create User on Machine";
            case 1: return "Delete User from Machine";
            case 2: return "Enroll Key";
            case 3: return "Remove Key";
            case 4: return "Update Permissions";
            case 5: return "Reinstall User on Machine";
            default: return "Unknown Action";
        }
    }

    return (
        <>
            <TableRow hover>
                <TableCell>
                    {functionToName(item)}
                </TableCell>
                <TableCell>{machineDisplay}</TableCell>
                <TableCell>{item.targetUserName}</TableCell>
                <TableCell />
            </TableRow>
        </>
    );
}

function QueuesIndexPage(props) {
    const {currentUser, machines, queue} = useLoaderData();
    const navigate = useNavigate();
    const fetcher = useFetcher();

    return <FullPageLayout
        title="Queued Machine Interactions"
        description="The following interactions are currently queued and wait for fulfillment."
        user={currentUser}
        onBack={() => navigate("/")}
    >
        <TableContainer component={Paper} variant="outlined">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Action</TableCell>
                        <TableCell>Machine</TableCell>
                        <TableCell>Affected User</TableCell>
                        <TableCell align="right" sx={{py: 0}}>
                            {currentUser.isAdmin() &&
                                <fetcher.Form method="post" action="/queues/">
                                    <Button type="submit" startIcon={<PlayArrow />}>Run Manually</Button>
                                </fetcher.Form>
                            }
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {queue.length === 0 && (
                        <TableRow>
                            <TableCell align="center" colSpan={4}>
                                No actions queued at the moment.
                            </TableCell>
                        </TableRow>
                    )}
                    {queue.map((item) => (
                        <QueuesItem
                            key={`queue-row-${item.id}`}
                            item={item}
                            machines={machines}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </FullPageLayout>;
}

export default QueuesIndexPage;