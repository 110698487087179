import React from "react";
import {
    Alert,
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Divider, IconButton,
    TextField, Toolbar, Typography, useMediaQuery, useTheme
} from "@mui/material";
import {Edit, Close} from "@mui/icons-material";
import {useFetcher} from "react-router-dom";
import SlideUpTransition from "./SlideUpTransition";
import MachineTypeSelector from "./MachineTypeSelector";

function MachineEditDialog({ machine, machineTypes }) {
    const fetcher = useFetcher();
    const useFullScreenDialog = useMediaQuery(useTheme().breakpoints.down('md'));

    const [dialogState, setDialogState] = React.useState({
        open: false,
        loading: false,
        errors: []
    });

    React.useEffect(() => {
        const actionData = fetcher.data;
        if (Array.isArray(actionData)) {
            setDialogState(dialogState => {
                if (!dialogState.loading) {
                    return dialogState;
                }

                return {
                    ...dialogState,
                    open: actionData.length !== 0,
                    loading: false,
                    errors: actionData
                };
            });
        }
    }, [fetcher.data]);

    const [messages, setMessages] = React.useState({
        displayName: null,
        hostName: null,
        port: null,
    });

    const refs = {
        displayName: React.useRef(null),
        hostName: React.useRef(null),
        port: React.useRef(null),
    }

    const fieldChangeHandler = e => {
        if (messages[e.target.name] !== null) {
            setMessages({
                ...messages,
                [e.target.name]: null
            });
        }
    }

    const closeHandler = e => {
        setDialogState({
            ...dialogState,
            open: false,
            errors: [],
        });
    }

    const openHandler = e => {
        setDialogState({
            ...dialogState,
            open: true,
            errors: [],
        });
    }

    const submitHandler = e => {
        let success = true;
        let newMessages = {};

        if (refs.hostName.current.value.match(/[^0-9a-z.\-_]/i)) {
            newMessages.hostName = "Hostname may only consist of alphanumerical characters and dots, dashes and underscores.";
            success = false;
        }

        if (!success) {
            e.preventDefault();
            setMessages({
                ...messages,
                ...newMessages
            });
            return;
        }

        setDialogState({
            ...dialogState,
            loading: true
        });
    }

    return (
        <>
            <Button startIcon={<Edit />} type="button" onClick={openHandler}>
                Edit machine
            </Button>

            <Dialog
                open={dialogState.open}
                onClose={closeHandler}
                maxWidth="md"
                fullWidth
                fullScreen={useFullScreenDialog}
                TransitionComponent={useFullScreenDialog ? SlideUpTransition : undefined}
            >
                <fetcher.Form method="put" action={`/machines/${machine.id}/updateConfig`} onSubmit={submitHandler}>
                    { useFullScreenDialog &&
                        <>
                            <AppBar>
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={closeHandler}
                                        aria-label="close"
                                    >
                                        <Close />
                                    </IconButton>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h3" component="div">
                                        Edit machine
                                    </Typography>
                                    <Button autoFocus color="inherit" type="submit">
                                        Save
                                    </Button>
                                </Toolbar>
                            </AppBar>
                            <Toolbar />
                        </>
                    }
                    { useFullScreenDialog ||
                    <DialogTitle>Edit machine {machine.displayName}</DialogTitle>
                    }
                    <DialogContent>
                        <DialogContentText>
                            Please fill in the following fields to change the machine.
                        </DialogContentText>


                        <Divider variant="middle" sx={{mt: 4, mb: 4}} />

                        <Typography variant="h3" gutterBottom>
                            Machine type
                        </Typography>

                        <MachineTypeSelector machineTypes={machineTypes} name="machineType" value={machine.machineType} />

                        <Divider variant="middle" sx={{mt: 4, mb: 4}} />

                        <Typography variant="h3" gutterBottom>
                            Configuration
                        </Typography>

                        {/*
                    Our machine model allows for custom configurations as JSON objects
                    This feature hasn't been used yet, so we just send an empty object.
                    If a use-case emerges, we will replace this with a minimal JSON editor.
                    */}
                        <input type="hidden" name="configuration" value="{}"/>

                        <TextField
                            fullWidth
                            required
                            error={messages.displayName !== null}
                            label="Display name"
                            variant="outlined"
                            name="displayName"
                            inputRef={refs.displayName}
                            defaultValue={machine.displayName}
                            onChange={fieldChangeHandler}
                            helperText={messages.displayName !== null ? messages.displayName : "The name shown in the web interface."}
                        />

                        <TextField
                            fullWidth
                            required
                            error={messages.port !== null}
                            label="Port"
                            variant="outlined"
                            name="port"
                            inputRef={refs.port}
                            onChange={fieldChangeHandler}
                            defaultValue={machine.port}
                            type="number"
                            min="1"
                            max="65535"
                            helperText={messages.port !== null ? messages.port : "Used to connect onto the machine."}
                        />

                        <TextField
                            fullWidth
                            required
                            error={messages.hostName !== null}
                            label="Hostname"
                            variant="outlined"
                            name="hostName"
                            inputRef={refs.hostName}
                            defaultValue={machine.hostName}
                            onChange={fieldChangeHandler}
                            helperText={messages.hostName !== null ? messages.hostName : "Used to connect onto the machine."}
                        />

                        {
                            dialogState.errors.map((message, index) => (
                                <Alert key={`alert-machineedit-${index}`} variant="outlined" severity="error" sx={{mt: 2}}>
                                    {message}
                                </Alert>
                            ))
                        }

                    </DialogContent>
                    { useFullScreenDialog ||
                    <DialogActions>
                        <Button onClick={closeHandler} type="button">Cancel</Button>
                        <Button variant="contained" type="submit">Change machine</Button>
                    </DialogActions>
                    }
                </fetcher.Form>
            </Dialog>
        </>
    )
}

export default MachineEditDialog;