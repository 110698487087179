import {
    Card,
    CardContent,
    CardHeader, CircularProgress, IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText, Tooltip
} from "@mui/material";
import {Badge, Dns, LocationOn, Refresh, Sell} from "@mui/icons-material";
import React from "react";
import MachineEditDialog from "./MachineEditDialog";
import {useFetcher} from "react-router-dom";

function MachineOverviewCard({
    machine,
    machineTypes,
    canEdit = false,
}) {

    let machineTypeDisplay = "Unknown";
    machineTypes.forEach(([identifier, descriptor]) => {
        if (machine.machineType === identifier) {
            machineTypeDisplay = descriptor;
        }
    });

    const fetcher = useFetcher();

    return (
        <Card variant="outlined">
            <CardHeader
                avatar={<Dns />}
                title="Configuration"
                action={
                    canEdit &&
                    <MachineEditDialog machine={machine} machineTypes={machineTypes} />
                }>
            </CardHeader>
            <CardContent sx={{p: 0}}>
                <List dense>
                    <ListItem>
                        <ListItemIcon>
                            <Badge />
                        </ListItemIcon>
                        <ListItemText primary={machine.displayName} secondary="Display name"/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <LocationOn />
                        </ListItemIcon>
                        <ListItemText primary={machine.hostName + " (Port " + machine.port + ")"} secondary="Host name"/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <Sell />
                        </ListItemIcon>
                        <ListItemText primary={machineTypeDisplay} secondary="Machine type"/>
                    </ListItem>
                    <ListItem secondaryAction={canEdit &&
                        <fetcher.Form action={`/machines/${machine.id}/refresh`} method='post'>
                            <Tooltip title="Refresh status" placement="left">
                                {fetcher.state === "idle" ?
                                <IconButton type="submit" edge="end">
                                    <Refresh />
                                </IconButton> :
                                <CircularProgress size={20} />
                                }
                            </Tooltip>
                        </fetcher.Form>
                    }>
                        <ListItemIcon>
                            <Dns color={machine.online === true ? "success" : "error"} />
                        </ListItemIcon>
                        <ListItemText primary={machine.online ? "Recently seen online" : "Recently seen offline"} secondary="Connectivity status"/>
                    </ListItem>
                </List>
            </CardContent>
            <span></span>
        </Card>
    )
}

export default MachineOverviewCard;