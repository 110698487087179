import {
    Button,
    Card, CardActions, CardContent, CardHeader, Paper,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from "@mui/material";
import {PlayArrow, Receipt} from "@mui/icons-material";
import React from "react";
import {useFetcher} from "react-router-dom";
import {QueuesItem} from "../../pages/queues/Index";

function MachineQueuesCard({
    machine,
    queue
}) {
    const fetcher = useFetcher();

    return (
        <Card variant="outlined">
            <CardHeader
                avatar={<Receipt />}
                title="Queued Interactions"
                action={
                    <fetcher.Form method="post" action={`/machines/${machine.id}/queues`}>
                        <Button type="submit" startIcon={<PlayArrow />}>Run Manually</Button>
                    </fetcher.Form>
                }
            />
            <CardContent sx={{px: 0, pt: 0}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Action</TableCell>
                            <TableCell>Machine</TableCell>
                            <TableCell>Affected User</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {queue.length === 0 && (
                            <TableRow>
                                <TableCell align="center" colSpan={4}>
                                    No actions queued at the moment.
                                </TableCell>
                            </TableRow>
                        )}
                        {queue.map((item) => (
                            <QueuesItem
                                key={`mach-queue-row-${item.id}`}
                                item={item}
                                machines={[machine]}
                            />
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
            <CardActions sx={{p: 0}}></CardActions>
        </Card>
    )
}

export default MachineQueuesCard;