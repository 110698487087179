import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    List,
    ListItem,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import {ArrowForward, Badge, Face, Person, Security} from "@mui/icons-material";
import React from "react";
import {useNavigate} from "react-router-dom";
import PasswordChangeDialog from "./PasswordChangeDialog";
import SshKeysList from "./SshKeysList";
import UserProfileEditDialog from "./UserProfileEditDialog";

function UserProfileCard({
    user,
    showAllUsersVisible = false,
    showChangePassword = false,
    showChangeProfile = false,
    canChangeDisplayName = true,
    canChangeRole = false,
    canChangeSshKeys = false,
    changePasswordRequiresOld = true,
    hideHeader = false
}) {

    const navigate = useNavigate();

    return <Card variant="outlined">
        {hideHeader ||
        <CardHeader
            avatar={<Person />}
            title="Profile"
            action={
                showChangeProfile &&
                <UserProfileEditDialog user={user} canChangeDisplayName={canChangeDisplayName} canChangeRole={canChangeRole} />
            }>
        </CardHeader>
        }
        <CardContent sx={{p: 0}}>
            <List dense>
                <ListItem>
                    <ListItemIcon>
                        <Face/>
                    </ListItemIcon>
                    <ListItemText primary={user.displayName} secondary="Display name"/>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Badge/>
                    </ListItemIcon>
                    <ListItemText primary={user.userName} secondary="User name"/>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <Security/>
                    </ListItemIcon>
                    <ListItemText primary={user.getRoleText()} secondary="User role"/>
                </ListItem>
                <SshKeysList user={user} canChangeSshKeys={canChangeSshKeys} />
            </List>
        </CardContent>
        <CardActions sx={{p: 0}}></CardActions>
        { (showAllUsersVisible || showChangePassword) &&
        <CardActions>
            { showAllUsersVisible &&
                <>
                    <Button startIcon={<ArrowForward/>} onClick={() => navigate("users")}>
                        Show all users
                    </Button>
                    <Button startIcon={<ArrowForward/>} onClick={() => navigate(`users/${user.id}`)}>
                        Manage this user
                    </Button>
                </>
            }
            { showChangePassword &&
                <PasswordChangeDialog user={user} requireOldPassword={changePasswordRequiresOld} />
            }
        </CardActions>
        }
    </Card>
}

export default UserProfileCard;